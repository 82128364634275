import Chuck1 from "../../assets/graduation_photos/Chuck1.jpeg";
import Chuck2 from "../../assets/graduation_photos/Chuck2.jpeg";
import Delina1 from "../../assets/graduation_photos/Delina1.jpeg";
import Group1 from "../../assets/graduation_photos/Group1.jpeg";
import Group2 from "../../assets/graduation_photos/Group2.jpeg";
import Group3 from "../../assets/graduation_photos/Group3.jpeg";
import Group4 from "../../assets/graduation_photos/Group4.jpeg";
import Group5 from "../../assets/graduation_photos/Group5.jpeg";
import Sajjaad1 from "../../assets/graduation_photos/Sajjaad1.jpeg";
import Sajjaad2 from "../../assets/graduation_photos/Sajjaad2.jpeg";
import Sajjaad3 from "../../assets/graduation_photos/Sajjaad3.jpeg";
import Sajjaad4 from "../../assets/graduation_photos/Sajjaad4.jpeg";
import Sajjaad5 from "../../assets/graduation_photos/Sajjaad5.jpeg";
import Umar1 from "../../assets/graduation_photos/Umar1.jpeg";
import Umar2 from "../../assets/graduation_photos/Umar2.jpeg";
import Umar3 from "../../assets/graduation_photos/Umar3.jpeg";
import Umar4 from "../../assets/graduation_photos/Umar4.jpeg";
import Zach1 from "../../assets/graduation_photos/Zach1.jpeg";

export const photos = [
    { src: Chuck1, width: 3, height: 2 },
    { src: Chuck2, width: 3, height: 2 },
    { src: Delina1, width: 6, height: 9 },
    { src: Group1, width: 6, height: 9 },
    { src: Group2, width: 6, height: 9 },
    { src: Group3, width: 6, height: 9 },
    { src: Group4, width: 3, height: 2 },
    { src: Group5, width: 6, height: 9 },
    { src: Sajjaad1, width: 3, height: 2 },
    { src: Sajjaad2, width: 2, height: 3 },
    { src: Sajjaad3, width: 3, height: 2 },
    { src: Sajjaad4, width: 6, height: 9 },
    { src: Sajjaad5, width: 2, height: 3 },
    { src: Umar1, width: 6, height: 9 },
    { src: Umar2, width: 6, height: 9 },
    { src: Umar3, width: 6, height: 9 },
    { src: Umar4, width: 6, height: 9 },
    { src: Zach1, width: 3, height: 2 },
];

