import React from 'react';
import './footer.css';
import youtube from '../../assets/youtube.png';
import instagram from '../../assets/instagram.png';
import tiktok from '../../assets/tiktok.png';

const Footer = () => {
  return (
    <div className="haris_films__footer">
      <div className="haris_films__footer-containers">
        <div className="haris_films__footer-containers-socials">
          <img src={youtube}/>
          <img src={instagram}/>
          <img src={tiktok}/>
        </div>
        <div className="haris_films__footer-containers-links">
          <p>Home</p>
          <p>Work</p>
          <p>Pricing</p>
          <p>Contact</p>
          <p>About</p>
        </div>
        <div className="haris_films__footer-containers-copyright">
          <p>All content Copyright © 2023 Haris Films</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
