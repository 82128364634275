import React from 'react'
import PhotoAlbum from "react-photo-album";
import { photos } from "./imports.js";
import './work.css'

const Gallery = () => <PhotoAlbum layout="masonry" photos={photos} columns={3} spacing={20} />;

const Work = () => {
  return (
    <div className="haris_films__graduation-photos">
        <div className="haris_films__graduation-photos-content">
            <h1>Graduation Photos</h1>
            <p>Commencement is not the end, it's the beginning. Celebrate your new beginning with photos that will last you a lifetime. </p>
        </div>
        <div className="haris_films__graduation-photos-gallery">
            <Gallery />
        </div>
        <div className="haris_films__graduation-photos-rates">
            <h2>Available Packages</h2>
            <div className="haris_films__graduation-photos-rates-container">
              <h3>Basic Solo Package - Starting at $200</h3>
              <ul>
                <li>1-Hour Shoot for 1 Individual</li>
                <li>Covers at least 20 Edited Photos</li>
                <li>Up to 2 wardrobe changes within timeframe</li>
              </ul>
              <h3>Group Celebration Package - Starting at $450</h3>
              <ul>
                <li>1.5-Hour Shoot with family and friends</li>
                <li>Covers at least 40 Edited Photos</li>
              </ul>
            </div>
        </div>
    </div>
  )
}

export default Work
