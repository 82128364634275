import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { Navbar } from './components';
import { About, Header, Footer, Services, Work } from './containers';
import './app.css';
import './index.css';

const App = () => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <div className="wrapper">
        <Navbar />
        <Routes>
          <Route path="/" element={<><Header /><About /><Services /></>} />
          <Route path='/work' element={<Work />} />
          <Route path='/services' element={<Services />} />
        </Routes>
        <Footer />
        </div>
      </div>
    </div>
  )
}

export default App
